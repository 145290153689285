interface IMenu {
	title: string;
	description?: string;
	items: Item_t[];
}

interface Item_t {
	title: string;
	price?: number;
	description?: string;
}

const menu: IMenu[] = [
	{
		title: "Crêpes",
		description: "Med nutella, chokladsås och frukt",
		items: [
			{
				title: "Crêpes med frukt",
				price: 79
			},{
				title: "Crêpes med orio",
				price: 79
			},{
				title: "Crêpes med lotus",
				price: 79
			},{
				title: "Mix Crêpes",
				price: 110,
				description: "Jordgubb, banan, glass"
			}
		]
	},{
		title: "Pannkakor",
		description: "Med nutella, chokladsås och frukt",
		items: [
			{
				title: "Pannkakor med frukt",
				price: 79
			},{
				title: "Pannkakor med orio",
				price: 79
			},{
				title: "Pannkakor med lotus",
				price: 79
			},{
				title: "Mix pannkakor",
				price: 110,
				description: "Jordgubb, banan, glass (för 1 personer)"
			}
		]
	},{
		title: "Mini Pannkakor",
		description: "Med nutella, chokladsås och frukt",
		items: [
			{
				title: "Mini pannkakor med frukt",
				price: 79
			},{
				title: "Mini pannkakor med orio",
				price: 79
			},{
				title: "Mini pannkakor med lotus",
				price: 79
			}
		]
	},{
		title: "Våfflor",
		description: "Med nutella chokladsås och frukt",
		items: [
			{
				title: "Våfflor med frukt",
				price: 79
			},{
				title: "Våfflor med orio",
				price: 79
			},{
				title: "Våfflor med lotus",
				price: 79
			}
		]
	},{
		title: "Smoothies",
		description: "Normal 35:- | Stor 45:-",
		items: [
			{
				title: "Mango",
				description: "Mjölk, banan, mango, ingefära"
			},{
				title: "Hallon",
				description: "Mjölk, grekisk yoghurt, hallon, banan"
			},{
				title: "GoGreen",
				description: "Mjölk, banan, spenat, avokado"
			},{
				title: "Blåbär",
				description: "Mjölk, grekisk yoghurt, blåbär, banan"
			},{
				title: "Banan och honung",
				description: "Mjölk, banan, honung"
			},{
				title: "Banan och choklad",
				description: "Mjölk, banan, kakaopulver"
			},{
				title: "Mixa din egen smoothie",
				description: "Mjölk + 3 valfria ingredienser"
			}
		]
	},{
		title: "Milkshake",
		items: [
			{
				title: "Jordgubb",
				price: 45
			},{
				title: "Banan",
				price: 45
			},{
				title: "Choklad",
				price: 45
			},{
				title: "Mango",
				price: 45
			},{
				title: "Hallon",
				price: 45
			},{
				title: "Blåbär",
				price: 45
			},{
				title: "Ananas",
				price: 45
			},{
				title: "Karamell",
				price: 49,
				description: "Med biscuit och grädde"
			},{
				title: "Karamell",
				price: 49,
				description: "Med orio och grädde"
			}
		]
	},{
		title: "Varma Drycker",
		items: [
			{
				title: "Baklava och glass",
				price: 30
			},{
				title: "Te",
				price: 24
			},{
				title: "Kaffe",
				price: 20
			},{
				title: "Kaffe Latte",
				price: 35
			},{
				title: "Cappuccino",
				price: 35
			},{
				title: "Espresso",
				price: 25
			},{
				title: "Dubbel Espresso",
				price: 35
			},{
				title: "Arabiskt Kaffe",
				price: 29
			},{
				title: "Turkiskt Kaffe",
				price: 29
			},{
				title: "Varm Choklad",
				price: 35
			},{
				title: "Varm Choklad",
				price: 45,
				description: "Med grädde och marshmallows"
			},{
				title: "Varm Choklad",
				price: 35,
				description: "Med kräm"
			}
		]
	}
];

export default menu