import Home from './pages/Home'
import Menu from './pages/Menu'

export interface CustomRedirect {
	enabled: boolean
	to: string
	from: string
}

export interface CustomRoute {
	path: string
	element: JSX.Element | any
	redirect?: CustomRedirect
}

export const routes: CustomRoute[] = [
	{
		path: '/',
		element: Home
	},{
		path: '/menu',
		element: Menu
	}
]