import React, { useEffect } from 'react'
import { Card } from 'react-bootstrap'

import menu from '../../menu'

export default function Preview()
{
	return (
		<>
			<div className="container">
				<div id="menu" className="page">
					<h1 className="title">Menu</h1>
					<p className="subtitle">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
					<Card className="card-menu shadow">
					{
						menu.map((category: any, cat_index: number) => (
							<div className="menu-list">
								<h1 key={cat_index}>{category.title}</h1>
								<p>{category.description}</p>
								<ul>
									{
										category.items.map((item: any, index: number) => (
											<li className="menu-item">
												<div className="menu-item-name">
													<span>{item.title}</span>
													<span>{item.price}{item.price && ':-'}</span>
												</div>
												<small>{item.description}</small>
											</li>
										))
									}
								</ul>
							</div>
						))
					}
					</Card>
				</div>
			</div>
		</>
	)
}