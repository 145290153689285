import { HashLink as Link } from 'react-router-hash-link';
import { Nav, Navbar } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
	faHome,
	faAddressBook,
	faTableList,
	faMugHot
} from '@fortawesome/free-solid-svg-icons'
import {
	faBars
} from '@fortawesome/free-solid-svg-icons'

export default function Navigation(props: any)
{
	return (
		<Navbar variant="dark">
			<Navbar.Brand href="/">Lial Café</Navbar.Brand>
			<Navbar.Toggle>
				<FontAwesomeIcon icon={faBars} fixedWidth />
			</Navbar.Toggle>
			<Navbar.Collapse>
				<Nav className="me-auto">
					<Link className="nav-link" to="/#top">
						<FontAwesomeIcon icon={faHome} fixedWidth />
						Home
					</Link>
					<Link className="nav-link" to="/#story">
						<FontAwesomeIcon icon={faMugHot} fixedWidth />
						Our Story
					</Link>
					<Link className="nav-link" to="/menu#top">
						<FontAwesomeIcon icon={faTableList} fixedWidth />
						Menu
					</Link>
					<Link className="nav-link" to="/#contact" >
						<FontAwesomeIcon icon={faAddressBook} fixedWidth />
						Contact
					</Link>
				</Nav>
			</Navbar.Collapse>
		</Navbar>
	)
}
