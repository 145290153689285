interface IAddress {
	street: string;
	city: string;
	country: string;
	latitude: number,
	longitude: number,
}

interface IOpeningTimes {
	monday: string;
	saturday: string;
	sunday: string;
}

interface IContactDetails {
	email: string;
	phone: string;
	address: IAddress;
	openingTimes: IOpeningTimes;
}

interface IConfig {
	name: string;
	contact: IContactDetails;
}

const config: IConfig = {
	name: "Lial Café",
	contact: {
		email: "info@lialcafe.se",
		phone: "0451 126 66",
		address: {
			street: "Järnvägsgatan 13B",
			city: "Hässleholm, 281 31",
			country: "Sweden",
			latitude: 56.15827610060495,
			longitude: 13.7641849995251
		},
		openingTimes: {
			monday: "10:00 - 19:00",
			saturday: "10:00 - 17:00",
			sunday: "09:00 - 15:00"
		}
	}
}
export default config