import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { routes, CustomRoute } from './routes'

import Layout from './components/Layout'

import './styles/app.sass'

function AppWithCallbackAfterRender() {
	return (
		<Router>
			<Layout>
				<Routes>
				{
					routes.map((route: CustomRoute, key: number) => {
						return <Route path={route.path} element={<route.element />} key={key} />
					})
				}
				</Routes>
			</Layout>
		</Router>
	)
}

const app = document.getElementById('app')
const root = createRoot(app!)
root.render(<AppWithCallbackAfterRender />);