import Footer from '../Footer'
import Navigation from '../Navigation'

import Cover from '../../assets/images/cover.jpg'

export default function Layout(props: any)
{
	return (
		<>
			<div className="wrapper">
				<img src={Cover} className="cover-image" alt="" loading="lazy" />
				<Navigation />
				
				{/* Render Content and routes */}
				{ props.children }
			</div>
			<Footer />
		</>
 	)
}