import React from 'react'
import Slider from 'react-slick'
import config from '../../config'
import { Button } from 'react-bootstrap'

import L from 'leaflet';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'

import Logo from '../../assets/images/Logo.svg'
import Icon from '../../assets/images/Icon.svg'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
	faAt,
	faLocationDot,
	faMobile,
} from '@fortawesome/free-solid-svg-icons'

import image1 from '../../assets/images/carousel/image_1.jpg'
import image2 from '../../assets/images/carousel/image_2.jpg'
import image3 from '../../assets/images/carousel/image_3.jpg'
import image4 from '../../assets/images/carousel/image_4.jpg'
import image5 from '../../assets/images/carousel/image_5.jpg'
import image6 from '../../assets/images/carousel/image_6.jpg'
import image7 from '../../assets/images/carousel/image_7.jpg'

export default function Home()
{
	let slider = {
		autoplay: true,
		autoplaySpeed: 3000,
		speed: 500,
		arrows: false,
		dots: false,
		infinite: true,
		centerMode: true,
		slidesToShow: 1,
		slidesToScroll: 1,
		variableWidth: true
	};

	let mapIcon = L.icon({
		iconUrl: Icon,
		iconRetinaUrl: Icon,
		iconAnchor: [24, 24],
		popupAnchor: [0, -24],
		iconSize: [48, 48],
	});

	return (
		<div className="container">
			<div className="page" id="cover">
				<h1 className="title">The Best Brew</h1>
				<h1 className="title">To Start Your Day</h1>
				<p className="subtitle">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
				<Button variant="primary" size="lg" className="btn-lg">
					Read More...
				</Button>
			</div>

			<div className="page" id="story">
				<div className="section">
					<img className="logo" src={Logo} alt="Lial Café" loading="lazy" />
				</div>
				<div className="divider"></div>
				<div className="section">
					<h1 className="title">Our Story</h1>
					<div className="section-content">
						<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
						<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
					</div>
				</div>
			</div>

			<Slider className="slider" {...slider}>
				<div>
					<img className="slider-image" alt="" src={image1} />
				</div>
				<div>
					<img className="slider-image" alt="" src={image2} />
				</div>
				<div>
					<img className="slider-image" alt="" src={image3} />
				</div>
				<div>
					<img className="slider-image" alt="" src={image4} />
				</div>
				<div>
					<img className="slider-image" alt="" src={image5} />
				</div>
				<div>
					<img className="slider-image" alt="" src={image6} />
				</div>
				<div>
					<img className="slider-image" alt="" src={image7} />
				</div>
			</Slider>

			<div className="page" id="contact">
				<h1 className="title">Contact</h1>
				<div className="section">
					<div className="section-content">
						<ul className="flex-column">
							<li>
								<div className="icon"><FontAwesomeIcon icon={faAt} fixedWidth /></div>
								<a className="copy" href={`mailto:${config.contact.email}`}>{ config.contact.email }</a>
							</li>
							<li>
								<div className="icon"><FontAwesomeIcon icon={faMobile} fixedWidth /></div>
								<span className="copy">{ config.contact.phone }</span>
							</li>
							<li>
								<div className="icon"><FontAwesomeIcon icon={faLocationDot} fixedWidth /></div>
								<ul className="flex-column address">
								{
									Object.values(config.contact.address).slice(0,3).map((value: string, index: number) => (
										<li key={index} className="copy">{ value }</li>
									))
								}
								</ul>
							</li>
						</ul>
					</div>
					<div className="section-content">
						<h5 className="subtitle">Opening Times</h5>
						<ul id="opening-times">
							<li>
								<span>Monday - Friday</span>
								<span>{ config.contact.openingTimes.monday }</span>
							</li>
							<li>
								<span>Saturday</span>
								<span>{ config.contact.openingTimes.saturday }</span>
							</li>
							<li>
								<span>Sunday</span>
								<span>{ config.contact.openingTimes.sunday }</span>
							</li>
						</ul>
					</div>
				</div>

				<div className="map-container">
					<MapContainer
						center={{
							lat: config.contact.address.latitude,
							lng: config.contact.address.longitude
						}}
						zoom={15}
						scrollWheelZoom={true}
						style={{ width: '100%', height: '100%' }}
					>
						<TileLayer url={`https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/256/{z}/{x}/{y}@2x?access_token=sk.eyJ1IjoiendvbG9mIiwiYSI6ImNsMmV0aWZ4ZzAydWUzY3JuOWU0NnpmNDEifQ.m4Fv3mpnd1LiRKLQR3P44A`} />

						<Marker
							icon={mapIcon}
							position={[
								config.contact.address.latitude,
								config.contact.address.longitude
							]}
						>
							<Popup>
								<div className="leaflet-marker-popup">
									Lial Café
									<small>
										<ul className="flex-column address">
										{
											Object.values(config.contact.address).slice(0,3).map((value: string, index: number) => (
												<li key={index} className="copy">{ value }</li>
											))
										}
										</ul>
									</small>
								</div>
							</Popup>
						</Marker>
					</MapContainer>
				</div>

			</div>

		</div>
	)
}