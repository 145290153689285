import config from '../../config'
import { HashLink as Link } from 'react-router-hash-link';
import { Nav } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
	faHome,
	faAddressBook,
	faAt,
	faLocationDot,
	faMobile,
	faCopyright,
	faTableList,
	faMugHot
} from '@fortawesome/free-solid-svg-icons'
import {
	faFacebook,
	faInstagram
} from '@fortawesome/free-brands-svg-icons'

export default function Footer(props: any)
{
	let year = new Date().getFullYear()

	return (
		<footer>
			<div className="grid">
				<div className="section">
					<strong>Navigation</strong>
					<Nav className="flex-column">
						<Link className="nav-link" to="/#top">
							<FontAwesomeIcon icon={faHome} fixedWidth />
							Home
						</Link>
						<Link className="nav-link" to="/#story">
							<FontAwesomeIcon icon={faMugHot} fixedWidth />
							Our Story
						</Link>
						<Link className="nav-link" to="/menu#top">
							<FontAwesomeIcon icon={faTableList} fixedWidth />
							Menu
						</Link>
						<Link className="nav-link" to="/#contact">
							<FontAwesomeIcon icon={faAddressBook} fixedWidth />
							Contact
						</Link>
					</Nav>
				</div>
				<div className="section">
					<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
					<div className="socials">
						<Link to="https://www.facebook.com/liafcafe"><FontAwesomeIcon icon={faFacebook} /></Link>
						<Link to=""><FontAwesomeIcon icon={faInstagram} /></Link>
					</div>
					<p>Brewed with ❤️ by <a href="https://joinbyte.com" target="_blank" rel="noreferrer">JoinByte</a>.</p>
				</div>
				<div className="section">
					<strong>Contact</strong>
					<ul className="flex-column">
						<li>
							<FontAwesomeIcon icon={faAt} fixedWidth />
							<a href={`mailto:${config.contact.email}`}>{ config.contact.email }</a>
						</li>
						<li>
							<FontAwesomeIcon icon={faMobile} fixedWidth />
							<span className="copy">{ config.contact.phone }</span>
						</li>
						<li>
							<FontAwesomeIcon icon={faLocationDot} fixedWidth />
							<ul className="flex-column address">
							{
								Object.values(config.contact.address).slice(0,3).map((value: string, index: number) => (
									<li key={index}><span className="copy">{ value }</span></li>
								))
							}
							</ul>
						</li>
					</ul>
				</div>
			</div>
			<p className="copyright">Lial Café <FontAwesomeIcon icon={faCopyright} /> { year }</p>
		</footer>
	)
}